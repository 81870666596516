/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {

        /* ---------------------------- Slider top home ---------------------------- */
        var swiper = new Swiper('.swiper-slider-home .swiper-container', {
            spaceBetween: 20,
            slidesPerView: 1,
            speed: 900,
            autoplay: {
                delay: 5000,
            },
            autoplayDisableOnInteraction: false,
            effect: 'fade',
            loop: true,
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            },
            on: {
                slideNextTransitionStart: function () {
                    if (jQuery(".swiper-slide-active").hasClass("switch_blanc")) { /* couleur blanc */
                        jQuery(".switch-txt").removeClass("c_noir");
                        jQuery(".switch-txt").addClass("c_blanc");

                    } else { /* couleur noir */
                        jQuery(".switch-txt").removeClass("c_blanc");
                        jQuery(".switch-txt").addClass("c_noir");
                    }
                }
            }
        });

        /* ---------------------------- Slider top home ---------------------------- */
        var swiper = new Swiper('.swiper-slider-single .swiper-container', {
            spaceBetween: 20,
            slidesPerView: 1,
            speed: 900,
            autoplay: 2500,
            autoplayDisableOnInteraction: false,
            effect: 'fade',
            loop: true,
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            }
        });

        /* ---------------------------- Slider actus home ---------------------------- */
        var swiper = new Swiper('.slider-list-post .swiper-container', {
            spaceBetween: 20,
            slidesPerView: 4,
            speed: 900,
            loop: true,
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            },
            autoplayDisableOnInteraction: true,
            breakpoints: {
                992: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                }
            }
        });

        /* ---------------------------- Slider actus single bottom ---------------------------- */
        var swiper = new Swiper('.slider-actu-bottom .swiper-container', {
            spaceBetween: 20,
            slidesPerView: 4,
            speed: 900,
            loop: true,
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            },
            autoplayDisableOnInteraction: true,
            breakpoints: {
                992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                }
            }
        });


        /* ---------------------------- Slider innovation ---------------------------- */
        var swiper = new Swiper('.swiper-slider-innovation .swiper-container', {
            spaceBetween: 20,
            slidesPerView: 1,
            speed: 900,
            autoplay: 1000,
            autoplayDisableOnInteraction: false,
            effect: 'fade',
            loop: true,
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            }
        });

        /* ---------------------------- Animation curseur et menu ---------------------------- */
        (function ($) {
            "use strict";
            /* ---------------------------- Page cursors ---------------------------- */
            document.getElementsByTagName("body")[0].addEventListener("mousemove", function (n) {
                t.style.left = n.clientX + "px",
                    t.style.top = n.clientY + "px",
                    e.style.left = n.clientX + "px",
                    e.style.top = n.clientY + "px",
                    i.style.left = n.clientX + "px",
                    i.style.top = n.clientY + "px"
            });
            var t = document.getElementById("cursor"),
                e = document.getElementById("cursor2"),
                i = document.getElementById("cursor3");

            function n(t) {
                e.classList.add("hover"), i.classList.add("hover")
            }

            function s(t) {
                e.classList.remove("hover"), i.classList.remove("hover")
            }

            s();
            for (var r = document.querySelectorAll(".hover-target"), a = r.length - 1; a >= 0; a--) {
                o(r[a])
            }

            function o(t) {
                t.addEventListener("mouseover", n), t.addEventListener("mouseout", s)
            }

            /* ---------------------------- Navigation ---------------------------- */
            var app = function () {
                var body = undefined;
                var menu = undefined;
                var init = function init() {
                    body = document.querySelector('body');
                    menu = document.querySelector('.menu-icon');
                    applyListeners();
                };
                var applyListeners = function applyListeners() {
                    menu.addEventListener('click', function () {
                        return toggleClass(body, 'nav-active');
                    });
                };
                var toggleClass = function toggleClass(element, stringClass) {
                    if (element.classList.contains(stringClass)) element.classList.remove(stringClass); else element.classList.add(stringClass);
                };
                var removeClass = function toggleClass(element, stringClass) {
                    if (element.classList.contains(stringClass)) element.classList.remove(stringClass); else element.classList.add(stringClass);
                };
                init();
            }();
        })(jQuery);

        $('body').on('click', '.side-button-search', function (e) {
            e.preventDefault();
            $('body').addClass('search-active');
        });
        $('body').on('click', '.form-close', function (e) {
            e.preventDefault();
            $('body').removeClass('search-active');
            $('body').removeClass('newsletter-active');
        });

        // newsletter
        $('body').on('click', '.side-button-newsletter', function (e) {
            e.preventDefault();

            openNewsletterForm();
        });

        var newsHash = window.location.hash;

        if (newsHash == '#newsletter') {
            openNewsletterForm();
        }

        function openNewsletterForm() {
            $('body').addClass('newsletter-active');

            window.location.hash = 'newsletter';

            ga('send', 'event', 'newsletter', 'open');
        }

        /* ---------------------------- Switch light/dark ---------------------------- */
        $("#switch").on('click', function () {
            if ($("body").hasClass("light")) {
                $("body").removeClass("light");
                $("#switch").removeClass("switched");
            }
            else {
                $("body").addClass("light");
                $("#switch").addClass("switched");
            }
        });

        /* ---------------------------- Fiche chantier single ---------------------------- */
        jQuery(document).on("click", ".fiche_chantier_off", function () {
            $(".fiche_chantier_on").show(200);
        });
        jQuery(document).on("click", ".fiche_chantier_on span", function () {
            $(".fiche_chantier_on").hide(200);
        });
        jQuery(document).on("click", ".switch-desc", function () {
            $(".content-rea").toggleClass("active");
        });


        jQuery(document).ready(function () {
            setTimeout(
                function () {
                    jQuery(".navfullmenu").css('opacity', '1');
                    jQuery(".newsfullmenu").css('opacity', '1');
                    jQuery(".searchfullmenu").css('opacity', '1');
                }, 2000)
        });

        $(window).scroll(function() {
            var newsBtn = $('.side-buttons .side-button-newsletter');

            var searchBtn = $('.side-buttons .side-button-search');

            var container = $('.side-buttons'),
                containerPos = $('.side-buttons').position();

            var scrollTop = $(window).scrollTop();

            $('.home .color-switch').each(function(key, value) {
                var section = $(this),
                    sectionPos = section.position();

                var trigger = scrollTop - sectionPos.top + containerPos.top + (container.outerHeight() / 2);

                if (trigger < section.outerHeight()) {
                    if (trigger >= 0) {
                        if (section.hasClass('section-light')) {
                            searchBtn.removeClass('c_blanc');
                            searchBtn.addClass('c_noir');

                            newsBtn.removeClass('c_blanc');
                            newsBtn.addClass('c_noir');
                        } else {
                            searchBtn.removeClass('c_noir');
                            searchBtn.addClass('c_blanc');

                            newsBtn.removeClass('c_noir');
                            newsBtn.addClass('c_blanc');
                        }
                        return false;
                    }
                }
            });
        });

    }
}
